<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">
				{{ $t('Storage.nav.SKU_water') }}

				<el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
					<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
				</el-tooltip>
			</h3>
		</div> -->
		
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">{{ $t('Storage.nav.SKU_water') }}</span>
						<el-tooltip effect="dark" :content="$t('i18nn_ff6fd823ffab7e6b')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
						</el-tooltip>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		
		<!-- <div class="formMsgCon">
        <h3><i class="titleIcon el-icon-bell"></i>{{$t('i18nn_41413cddfa61538c')}}</h3>
        <div class="formMsgDet">
          <p>
            此为录入仓库的SKU数据
          </p>
        </div>
    </div> -->
		
		<div class="filterCon" style="">
			<div class="filterBarList">
					
			</div>
			<div class="filterAction">
				<!-- <ul class="actionConList"> -->
					
				<!-- </ul> -->
				<ul class="filterConList">
					<li>
						<span><i class="red">*&nbsp;</i>{{$t('i18nn_2594059036dfede5')}}</span>
						<el-date-picker v-model="filterData.daterange" type="daterange" align="right"
							:clearable="false"
							unlink-panels range-separator="-"
							:start-placeholder="$t('i18nn_7d2ffc27a09ff792')"
							:end-placeholder="$t('i18nn_46f7be1133b3e2c6')" value-format="yyyy-MM-dd"
							format="yyyy-MM-dd" size="small" @change="initData()"
							:picker-options="pickerOptions"></el-date-picker>
					</li>
					
					<li>
						<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser @change="changeAgentUser"></SelAgentUser>
					</li>
					<li>
						<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo"></whNoSelect>
					</li>
					<li>
						<span>{{$t('i18nn_184333c81babf2f1')}}</span> 
						<el-select
							size="small"
							filterable
							clearable
							v-model="filterData.wh_goods_sku_Inout"
							:placeholder="$t('FormMsg.Please_select')"
							style="width: 100px;" 
						  @change="initData()"
						>
							<el-option v-for="item in selectOption.wh_goods_sku_Inout" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
						</el-select>
					</li>
					
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;"></el-input>
					</li>
					
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<!-- <li>
						<el-button icon="el-icon-s-operation" size="small" type="primary" @click="openMoreFilter()"></el-button>
					</li> -->
				</ul>
			</div>
		</div>
		
		<!-- <div class="wh-filter-con">
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
				<SelAgentUser @change="changeAgentUser"></SelAgentUser>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
				<whNoSelect @changeData="changWhNo"></whNoSelect>
			</div>
			<div class="wh-filter-item" >
			  <span>{{$t('i18nn_184333c81babf2f1')}}</span>
			 
				<el-select
					size="small"
					filterable
					clearable
					v-model="filterData.inOut"
					:placeholder="$t('FormMsg.Please_select')"
					style="width: 100px;" 
				  @change="initData()"
				>
					<el-option v-for="item in selectOption.inOut" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
				</el-select>
			</div>
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
				<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword" maxlength="50" :clearable="true" style="width: 150px;"></el-input>
			</div>
			<el-button type="primary" @click="serPageData" size="small">{{ $t('FormMsg.Search') }}</el-button>
		</div> -->

		<!-- <div class="wh-action-con">
        <el-button type="success" @click="openDioalog" size="small" icon="el-icon-plus">{{$t('Storage.skuInfo.Add_SKU')}}</el-button>
        <el-button type="warning" size="small" icon="el-icon-upload">{{$t('Storage.skuInfo.Import_SKU')}}</el-button>
    </div> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small" :max-height="$store.state.frameConHeightWh">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
				<!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->

				<!-- "id":"数据ID",
    "goodsSubjectId":"类目",
    "goodsSku":"SKU编码",
    "goodsName":"SKU名称",
    "goodsNameEn":"SKU英文标题",
    "goodsImg":"SKU图片",
    "declarePrice":"价值",
    "goodsWeight":"重量",
    "sizeUnit":"尺寸单位",
    "goodsLength":"长",
    "goodsWidth":"宽",
    "goodsHeight":"高",
    "safeStock":"安全库存"-->

				<!-- <el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
          <template slot-scope="scope">
            <el-tag type="" v-if="'1'==scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
            <el-tag type="success" v-else-if="'2'==scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
            <el-tag type="warning" v-else-if="'3'==scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
            <el-tag type="danger" v-else-if="'4'==scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
            <el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
          </template>
        </el-table-column> -->

				<!-- <el-table-column prop="cusName" :label="$t('i18nn_29991afa9781d554')"></el-table-column> -->

				<el-table-column prop="goodsSku" label="SKU"></el-table-column>

				<el-table-column prop="createTime" :label="$t('Storage.skuInfo.date')"></el-table-column>

				<el-table-column prop="stock" :label="$t('Storage.skuInfo.quantity')"></el-table-column>

				<el-table-column prop="relationNo" :label="$t('i18nn_158db9252e21d1a6')">
				
				</el-table-column>
				
				<el-table-column prop="sysOrderNo" :label="$t('i18nn_52a8c38184d84581')">
				
				</el-table-column>
				
				<el-table-column prop="packageNo" :label="'入库编号'">
				
				</el-table-column>

				<!-- <el-table-column prop="goodsImg" :label="$t('Storage.skuInfo.SKU_pictures')">
          <template slot-scope="scope">
            <div>
              <el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.goodsImg" @click="openBigImg($event, scope.row.goodsImg)">
                <div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i></div>
              </el-image>
            </div>
          </template>
        </el-table-column> -->

				<el-table-column prop="inOutName" :label="$t('Storage.skuInfo.type')">
					<template slot-scope="scope">
						<div>
							{{ $Utils.i18nKeyText(scope.row,'inOutName') }}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="zoneWhNo" :label="$t('i18nn_c944a6686d996ab3')"></el-table-column>
				
				<el-table-column prop="place" :label="$t('hytxs0000035')"></el-table-column>
				
				<el-table-column prop="remark" :label="$t('Storage.skuInfo.explain')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference" style="color:red;">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="sizeUnitName" :label="$t('i18nn_38bc71c0dc55904b')"></el-table-column>

        <el-table-column prop="goodsLength" :label="$t('Storage.skuInfo.length')"></el-table-column>
        <el-table-column prop="goodsWidth" :label="$t('Storage.skuInfo.width')"></el-table-column>
        <el-table-column prop="goodsHeight" :label="$t('Storage.skuInfo.height')"></el-table-column>
        <el-table-column prop="safeStock" :label="$t('Storage.skuInfo.safety_stock')"></el-table-column>

        <el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')"></el-table-column>

        <el-table-column :label="$t('Storage.tableColumn.operation')" width="200px" fixed="right">
          <template slot-scope="scope">
            <div v-if="isSel"><el-button @click="selRow($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-magic-stick">{{$t("FormMsg.Select")}}</el-button></div>
            <div v-else>
              <el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button>
              <el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button>
              </div>
          </template>
        </el-table-column> -->
			</el-table>

			<div class="tableCon_pagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>
		<!-- 修改弹窗 -->
		<!-- <el-dialog :close-on-click-modal="false" top="0"  title="添加SKU" append-to-body :visible.sync="dialogFormVisible"  custom-class="myFullDialog4">

      

      <el-form ref="form" :rules="formRules" :size="'small'" :model="form" label-width="150px" style="width: 1000px;" v-loading="loading" :element-loading-text="$t('i18nn_1b4525c800280581')">


         <el-form-item :label="$t('Storage.skuInfo.category')" prop="goodsSubjectId">
          <el-input type="text" v-model="form.goodsSubjectId" style="width: 300px;" :placeholder="$t('FormMsg.Please_Enter')"></el-input>
          <span class="form_msg">{{$t('i18nn_039b1175cdb03703')}}</span>
        </el-form-item>


        <el-form-item label="SKU：" prop="goodsSku">
          <el-input type="text" v-model="form.goodsSku" style="width: 300px;" :placeholder="$t('FormMsg.Please_Enter')"></el-input>
          <span class="form_msg">只能是数字字母下划线，不超过30字符</span>
        </el-form-item>

        <el-form-item :label="$t('Storage.skuInfo.SKU_title')" prop="goodsName">
          <el-input type="text" v-model="form.goodsName" style="width: 300px;" :placeholder="$t('FormMsg.Please_Enter')"></el-input>
          <span class="form_msg">建议不超过50字符</span>
    
        </el-form-item>
        <el-form-item label="SKU英文标题：" prop="goodsNameEn">
          <el-input type="text" v-model="form.goodsNameEn" style="width: 300px;" :placeholder="$t('FormMsg.Please_Enter')"></el-input>
          <span class="form_msg">建议不超过50字符</span>
        </el-form-item>

        <el-form-item label="单位：" prop="sizeUnit">
          <el-select filterable clearable size="mini" v-model="form.sizeUnit" style="width: 300px;" :placeholder="$t('FormMsg.Please_select')">
            <el-option v-for="item in selectOption.wh_vol_unit" :key="item.code" :label="$Utils.i18nCodeText(item)" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="长度：" prop="goodsLength">
          <el-input-number size="mini" v-model="form.goodsLength" controls-position="right" style="width: 300px;"></el-input-number>
        </el-form-item>
        <el-form-item label="宽度：" prop="goodsWidth">
          <el-input-number size="mini" v-model="form.goodsWidth" controls-position="right" style="width: 300px;"></el-input-number>
        </el-form-item>
        <el-form-item label="高度：" prop="goodsHeight">
          <el-input-number size="mini" v-model="form.goodsHeight" controls-position="right" style="width: 300px;"></el-input-number>
        </el-form-item>

        <el-form-item :label="$t('Storage.skuInfo.weight')" prop="goodsWeight">
          <el-input-number size="mini" v-model="form.goodsWeight" controls-position="right" style="width: 300px;"></el-input-number>
        </el-form-item>

        <el-form-item :label="$t('Storage.skuInfo.safety_stock')" prop="safeStock">
          <el-input-number size="mini" v-model="form.safeStock" controls-position="right" style="width: 300px;"></el-input-number>
        </el-form-item>

        <el-form-item :label="$t('Storage.skuInfo.price')" prop="declarePrice">
          <el-input-number size="mini" v-model="form.declarePrice" controls-position="right" style="width: 300px;"></el-input-number>
          <span class="form_msg">USD</span>
        </el-form-item>

        <el-form-item :label="$t('Storage.tableColumn.remark')">
          <el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark" maxlength="" show-word-limit style="width: 300px;"></el-input>
        </el-form-item>
      </el-form>
     
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="dialogFormVisible = false">{{$t('FormMsg.Close')}}</el-button>
        <el-button type="primary" @click="submitForm('form')">{{$t('FormMsg.Save')}}</el-button>
      </div>
    </el-dialog> -->

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
			<div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogImgVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->
		<!-- excel 导出排序 -->
		<whExcelCustom
			:openTime="excelOpenTime"
			:excelHead="excelHead"
			:excelData="expExcelData"
			:pagination="pagination"
			:excelName="excelName"
			:excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhMyGoodsSkuRecPageList"
			:expHttpFilter="pageFilterData()"
		></whExcelCustom>
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
// import excelUtilsNew from '@/utils/excelUtilsNew.js';
import SelAgentUser from '@/components/Common/SelAgentUser.vue';
import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';
import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
export default {
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	//   meta: [{ // set meta
	//     name: '互易天下-买家中心-收货账单',
	//     content: '互易天下-厂家共享平台-买家中心-收货账单'
	//   }],
	//   // link: [{                 // set link
	//   //   rel: 'asstes',
	//   //   href: 'https://assets-cdn.github.com/'
	//   // }]
	// },
	// props: ['isDialog'],
	components: {
		SelAgentUser,
		whExcelCustom,
		whNoSelect
	},
	props: {
		// mobile:"",
		isSel: {
			default: function() {
				return false;
			},
			type: Boolean
		},
		status: {
			default: function() {
				return '';
			},
			type: String
		}
	},
	data() {
		return {
			//excel导出
			excelOpenTime: '',
			excelHead: [],
			expExcelData: [],
			excelName: '',
			excelOption: {},
			
			pickerOptions: this.$PickerDate.pickerOptions_1(),
			// activeName: 'second',
			// isShowFrom:false,
			// pickerOptions1: {
			//     disabledDate(time) {
			//       return time.getTime() > Date.now();
			//     },
			//     shortcuts: [{
			//       text: this.$t('i18nn_0200bd47bb4bb83d'),
			//       onClick(picker) {
			//         picker.$emit('pick', new Date());
			//       }
			//     }, {
			//       text: this.$t('i18nn_84b73bfc6dada445'),
			//       onClick(picker) {
			//         const date = new Date();
			//         date.setTime(date.getTime() - 3600 * 1000 * 24);
			//         picker.$emit('pick', date);
			//       }
			//     }, {
			//       text: this.$t('i18nn_e3f3138cf5ba3f6d'),
			//       onClick(picker) {
			//         const date = new Date();
			//         date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
			//         picker.$emit('pick', date);
			//       }
			//     }]
			//   },
			// UserInfo: this.$store.getters.getUserInfo,
			// dialogFormVisible: false,
			dialogFormStatus: 0, //0-新增，1-修改

			//图片放大
			// dialogImgVisible: false,
			// imgUrlBigShow: '',

			loading: false,
			form: {
				id: null, //"数据ID",
				goodsSubjectId: '', //this.$t('i18nn_268e50d838264ca8'),
				goodsSku: '', //"SKU编码",
				goodsName: '', //"SKU名称",
				goodsNameEn: '', //"SKU英文标题",
				goodsImg: '', //"SKU图片",
				declarePrice: '', //this.$t('i18nn_9db48ed0e8ec64a6'),
				goodsWeight: '', //this.$t('i18nn_96f1a4b17e75892d'),
				sizeUnit: '', //this.$t('i18nn_38bc71c0dc55904b'),
				goodsLength: '', //this.$t('i18nn_0e60303b30d5d4b4'),
				goodsWidth: '', //this.$t('i18nn_6cd31871f8528dd5'),
				goodsHeight: '', //this.$t('i18nn_93c9ea4a0e52c641'),
				safeStock: '' //this.$t('i18nn_004617dc47191cb8')
			},

			formRules: {
				goodsSubjectId: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				goodsSku: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				goodsName: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				goodsNameEn: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				goodsImg: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				declarePrice: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				goodsWeight: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				sizeUnit: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				goodsLength: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				goodsWidth: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				goodsHeight: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				safeStock: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }]
			},
			loading_load: false,
			tableData: [],
			//分页数据
			pagination: this.$Utils.defaultPagination(),
			selectOption: {
				wh_weight_unit: [],
				wh_vol_unit: [],
				wh_goods_fee_type: [],
				// wh_no:[],
				wh_goods_sku_Inout:[
					// {
					// 	code: '1',
					// 	codeText: this.$t('i18nn_de548b6f239487e4')
					// },
					// {
					// 	code: '2',
					// 	codeText: this.$t('i18nn_3155418abb05309e')
					// }
				]
				// statusList: [
				//   {
				//     value: '',
				//     label: this.$t('i18nn_16853bda54120bf1')
				//   },
				//   {
				//     value: '1',
				//     label: this.$t('i18nn_fdd8426d7b8869c5')
				//   },
				//   {
				//     value: '2',
				//     label: this.$t('i18nn_619d7e563f879811')
				//   },
				//   {
				//     value: '3',
				//     label: this.$t('i18nn_1dfb3a065d3ffe39')
				//   }
				// ]
			},
			//查询，排序方式
			filterData: {
				orderBy: 'id_', //排序字段
				sortAsc: 'desc', //desc降序，asc升序

				agentUser: '',
				// "accountPeriod":"",
				// "billNo":""
				// status: '',
				// putWhNo: '',
				whNo: '',
				goodsSku: '',
				// hashCode: '',
				goodsName: '',
				goodsNameEn: '',
				keyword: '',
				wh_goods_sku_Inout:'',
				daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 365),this.$Utils.fomatterDate_YYYYMMdd(new Date())],
				
				// declareNameCh: '',
			}
		};
	},

	//创建时
	created() {
		// this.getPageData();
	},
	//编译挂载前
	mounted() {
		// this.getDicData();
		//数据字典
		getDicData(['wh_goods_sku_Inout'],
			(data)=>{
				this.selectOption.wh_goods_sku_Inout = data['wh_goods_sku_Inout'];
		});
		this.initData();
	},
	methods: {
		initData() {
			this.pagination.current_page = 1;
			// this.currentSel = {};
			// this.multipleSelection = [];
			this.getPageData();
			// this.getDicData();
		},
		changeAgentUser(val) {
			this.filterData.agentUser = val;
			this.initData();
		},
		changWhNo(data) {
			console.log('changWhNo', data);
			this.filterData.whNo = data.code;
			// this.filterData.userId = data.userId;
			this.initData();
		},
		//导出 excel
		exportExcel() {
			let columns = [
				{
					title: 'SKU',
					key: 'goodsSku'
				},
				{
					title: this.$t('Storage.skuInfo.date'),
					key: 'createTime'
				},
				{
					title: this.$t('Storage.skuInfo.quantity'),
					key: 'stock'
				},
				{
					title: this.$t('i18nn_158db9252e21d1a6'),
					key: 'relationNo'
				},
				{
					title: this.$t('i18nn_52a8c38184d84581'),
					key: 'sysOrderNo'
				},
				{
					title: '入库编号',
					key: 'packageNo'
				},
				
				{
					title: this.$t('Storage.skuInfo.type'),
					key: 'inOutName'
				},
				{
					title: this.$t('i18nn_c944a6686d996ab3'),
					key: 'zoneWhNo'
				},
				{
					title: this.$t('hytxs0000035'),
					key: 'place'
				},
				{
					title: this.$t('Storage.skuInfo.explain'),
					key: 'remark'
				}
			];
			// <el-table-column prop="whNo" :label="$t('i18nn_c944a6686d996ab3')"></el-table-column>
			let Data = this.tableData;
			
			// if (!this.filterData.userId) {
			// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
			// 	return;
			// }
			if (!this.filterData.daterange || this.filterData.daterange.length<2) {
				this.$message.warning(this.$t('i18nn_91e192ea5925de92'));
				return;
			}
			
			this.expExcelData = Data;
			this.excelHead = columns;
			this.excelName = 'WSkuRecord';
			this.excelOption = {height: 20};
			this.excelOpenTime = new Date().getTime();
			// excelUtilsNew.exportExcel(columns, Data, 'WSkuRecord', { height: 20 });
		},

		//跳转页面
		// toPageUrl(name){
		//   this.$router.push({'name':name});
		// },
		//打开新增编辑，弹窗
		// openDioalog(formParm) {
		//   // console.log(formParm);
		//   this.dialogFormVisible = true;
		//   let form = Object.assign({}, formParm);
		//   console.log('form',form);
		//   // // 重置
		//   this.resetForm('form');
		//   if (null === formParm) { //新增

		//     this.form.weightUnit = '1';
		//     this.form.volumeUnit = '1';
		//     this.form.whFeeType = '1';

		//     this.dialogFormStatus = 0;
		//     // form.subUserId = null;
		//     // form.userSubUserId = null;
		//     // form.state = true;
		//     //浅拷贝、对象属性的合并
		//     this.form = form;

		//   } else { //修改
		//     this.dialogFormStatus = 1;
		//     // form.state = form.state === '0' ? true : false;

		//     //浅拷贝、对象属性的合并
		//     this.form = form;

		//   }
		//   // this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
		//   //       type: 'warning',
		//   //       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//   //     });
		// },
		//打开编辑
		// openEdit(event, row, index) {
		//   event.stopPropagation();
		//   this.openDioalog(row, this.$t('FormMsg.Edit'));
		// },

		//删除
		// delAction(event, row) {
		//   this.$confirm(this.$t("FormMsg.confirm_Delete"), this.$t('tips.tipsTitle'), {
		//     // confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//     // cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
		//     type: 'warning'
		//   })
		//     .then(() => {
		//       // this.$message({
		//       //   type: 'success',
		//       //   message: '删除成功!'
		//       // });
		//       this.delDataAction(event, row);
		//     })
		//     .catch(() => {
		//       // this.$message({
		//       //   type: 'info',
		//       //   message: this.$t('i18nn_2e58cb9b52e2a214')
		//       // });
		//     });
		// },
		//删除
		// delDataAction(event, row) {
		//   event.stopPropagation();
		//   console.log('delDataAction', row);
		//   // let parm = [];

		// //   if (!!row) {
		// //     //单条
		// //     parm = [row.id];
		// //   } else {
		// //     //多条
		// // //     let dataList = this.multipleSelection;
		// // //     if (dataList.length < 1) {
		// // //       this.$message.warning(this.$t('i18nn_7b80e66b535a3732'));
		// // //       return;
		// // //     }

		// // //     let dataListParm = dataList.map(v => v.id);
		// // //     parm = dataListParm;
		// //     // console.log('dataListParm', dataListParm);
		// //     // let dataParm = {
		// //     //   ids: dataListParm
		// //     // };
		// //   }

		//   this.postData(this.$urlConfig.WhMyGoodsSkuDel+'/'+row.id, {},'delete');
		// },

		// hyUpLoadImg1: function(childValue) {
		// 	// childValue就是子组件传过来的值
		// 	this.form.goodsImg = childValue;
		// },

		//查看图片
		// openBigImg(event, imgsrc) {
		// 	event.stopPropagation();
		// 	console.log('openBigImg', imgsrc);
		// 	this.dialogImgVisible = true;
		// 	this.imgUrlBigShow = imgsrc;
		// },

		//点击状态为非出账弹出窗
		// noConfirm(){
		//   this.$alert(this.$t('i18nn_e741d17b1fd891c2')已出账this.$t('i18nn_d6f109b7bee2f33f')确认"操作，请核实！', this.$t('tips.tipsTitle'), {
		//         type: 'warning',
		//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//       });
		// },
		//提交信息
		// submitForm(formName) {

		//   this.$refs[formName].validate((valid) => {

		//     if (valid) {
		//       let formData = Object.assign({}, this.form);
		//       //浅拷贝、对象属性的合并
		//       if (0 === this.dialogFormStatus) {

		//         formData.id = null;
		//         formData.userId = this.UserInfo.id;

		//         this.postData(this.$urlConfig.WhMyGoodsSkuUpdate, formData);

		//       } else {

		//         formData.userId = this.UserInfo.id;
		//         this.postData(this.$urlConfig.WhMyGoodsSkuUpdate, formData);
		//       }

		//     } else {
		//       console.log('error submit!!');
		//       this.$alert(this.$t("tips.checkSubmitData"), this.$t('tips.tipsTitle'), {
		//         type: 'warning',
		//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//       });
		//       return false;
		//     }
		//   });
		// },
		//重置输入框
		// resetForm(formName) {
		//   console.log(formName);
		//   // console.log(this.$refs[formName]);

		//   if (this.$refs[formName]) {
		//     this.$refs[formName].resetFields();

		//   } else {
		//     console.log('this.$refs[formName]',this.$refs[formName]);
		//   }
		// },

		//提交信息
		// postData(url, formData,type) {
		//   // let _this = this;
		//   this.loading = true;
		//   let HttpType = {};
		//   if('delete'==type){
		//     HttpType = this.$http.delete(url, formData);
		//   } else {
		//     HttpType = this.$http.put(url, formData);
		//   }
		//   // formData.state = formData.state ? '0' : '1';
		//   HttpType
		//     .then(({ data }) => {
		//       console.log(this.$t('tips.submitSuccess'));
		//       console.log(data);
		//       
		//       this.loading = false;
		//       if (200 == data.code) {
		//         this.dialogFormVisible = false;
		//         this.getPageData();
		//         this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
		//           type: 'success',
		//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//         });
		//       } else {
		//         // if (!data.msg) {
		//         //   data.msg = this.$t('tips.submitError');
		//         // }
		//         this.$alert(data.msg?data.msg:this.$t("tips.submitError"), this.$t('tips.tipsTitle'), {
		//           type: 'warning',
		//           //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//         });
		//       }
		//     })
		//     .catch((error) => {
		//       console.log(error);
		//       console.log(this.$t("tips.submitError"));
		//       this.loading = false;
		//       this.$alert(this.$t("tips.submitRequestError"), this.$t('tips.tipsTitle'), {
		//         type: 'warning',
		//         //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//       });
		//     });
		// },
		//查询数据
		// serPageData() {
		// 	this.pagination.current_page = 1;
		// 	this.getPageData();
		// },
		//分页的筛选项数据
		pageFilterData() {
			let startTime = '';
			let endTime = '';
			if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
				startTime = this.filterData.daterange[0];
				endTime = this.filterData.daterange[1];
			} else {
				// this.$message.warning(this.$t('tips.Please_Sel_date'));
			}
			
			return {
				sortAsc: this.filterData.sortAsc,
				orderBy: this.filterData.orderBy,
				
				proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
				// status: this.filterData.status ? this.filterData.status : null,
				zoneWhNo: this.filterData.whNo ? this.filterData.whNo : null,
				goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
				// hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
				goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
				goodsNameEn: this.filterData.goodsNameEn ? this.filterData.goodsNameEn : null,
				keyword: this.filterData.keyword ? this.filterData.keyword : null,
				inOut: this.filterData.wh_goods_sku_Inout ? this.filterData.wh_goods_sku_Inout : null,
				createStart: startTime ? startTime : null,
				createEnd: endTime ? endTime : null,
				// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
			};
		},
		//请求分页数据
		getPageData() {
			
			// if (!this.filterData.userId) {
			// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
			// 	return;
			// }
			if (!this.filterData.daterange || this.filterData.daterange.length<2) {
				this.$message.warning(this.$t('i18nn_91e192ea5925de92'));
				return;
			}
			
			let filterData = Object.assign(
				{
					
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size, //当前页显示数目
				},
				this.pageFilterData()
			);
			// let _this = this;
			this.loading_load = true;
			this.$http
				.put(this.$urlConfig.WhMyGoodsSkuRecPageList, filterData)
				.then(({ data }) => {
					// console.log(this.$store.getters.getUserInfo);
					console.log('分页，请求成功');
					console.log(data);
					
					this.loading_load = false;
					//表格显示数据
					this.tableData = data.rows;
					//当前数据总条数
					this.pagination.total = parseInt(data.total);
					//当前页数
					// this.pagination.current_page = parseInt(data.current);
					//当前页条数
					// this.pagination.page_size = parseInt(data.size);
				})
				.catch(error => {
					console.log(error);
					console.log('分页，请求失败');
					this.loading_load = false;
				});
		},
		//操作
		// sureBillAction(row,msg,state) {
		//   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
		//   console.log(row);
		//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
		//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
		//     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
		//     type: 'warning'
		//   }).then(() => {
		//     //确认
		//     let dataParm = {
		//       ids:[row.id],
		//       state:state,
		//     }
		//     this.updatePageData(dataParm, msg);
		//   }).catch(() => {

		//   });

		// },
		//更新特定数据,
		// updatePageData(dataParm, msg) {
		//   // let _this = this;
		//   this.loading_load = true;
		//   //浅拷贝、对象属性的合并
		//   let dataParmAss = Object.assign({}, dataParm);
		//   // dataParmAss.isDefault = 1;
		//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
		//     .then(({ data }) => {
		//       console.log(msg + "特定数据，请求成功");
		//       console.log(data);
		//       if (200 == data.code) {
		//         this.$message.success(msg + '成功！');
		//         this.getPageData();
		//       } else {
		//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
		//       }
		//     })
		//     .catch((error) => {
		//       console.log(error);
		//       console.log("更新特定数据，请求失败");
		//       this.loading_load = false;
		//       this.$message.warning('' + msg + '失败,请重试！');
		//     });
		// },
		//选择数据后回调
		selRow(event, row) {
			event.stopPropagation();
			this.$emit('selectRow', row);
		},
		//查询数据字典
		// getDicData() {
		// 	// let _this = this;
		// 	// console.log(keyword);

		// 	// this.loading_load = true;
		// 	this.$http
		// 		.put(this.$urlConfig.HyDicQueryList, ['wh_goods_sku_Inout'])
		// 		.then(({ data }) => {
		// 			console.log('查询数据字典，请求成功');
		// 			console.log(data);
		// 			if (200 == data.code && data.data) {
		// 				this.selectOption.wh_goods_sku_Inout = data.data['wh_goods_sku_Inout'];
		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = this.$t('tips.submitError');
		// 				}
		// 				this.$message.warning(data.msg);
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('查询数据字典接口，请求失败');
		// 			this.$message.error(this.$t('tips.submitRequestError'));
		// 		});
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/deep/ .el-input-number,
.el-select {
	// width: 100px;
	.el-input__inner {
		text-align: left;
	}
}
.form_msg {
	color: #e6a23c;
}
</style>
